<template>
  <v-container class="login" fluid>
    <v-card class="center-card">
      <h1>{{ $t('studentLogin') }}</h1>
      <v-card-text>
        <v-form v-model="formValid" :disabled="processing" @submit.prevent="formValid && submitLogin()">
          <v-text-field
            class="mb-6"
            v-model="username"
            :label="$t('account')"
            solo
            flat
            :rules="[v => $validate.required(v) || $t('message.pleaseEnterAccount')]"
            :readonly="usernameDisabled"
          ></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            :label="$t('password')"
            solo
            flat
            :rules="[v => $validate.required(v) || $t('message.pleaseEnterPassword')]"
          ></v-text-field>
          <v-btn
            block
            color="accent"
            class="mt-3 black--text"
            depressed
            :loading="processing"
            :disabled="!formValid"
            type="submit"
          >{{ $t('login') }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="loginError" timeout="4000" color="error" top>
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="white">
          {{ icon.mdiAlertOctagon }}
        </v-icon>
        {{ errorMsg }}
      </div>
      <template #action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="loginError = false">
          <v-icon>{{ icon.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mdiClose, mdiAlertOctagon } from '@mdi/js'
import { defaultPage } from '@/assets/cannotAccessList'

export default {
  name: 'StudentLogin',
  computed: {
    processing() {
      return this.$store.getters.isLoading
    },
  },
  data: () => ({
    icon: {
      mdiAlertOctagon,
      mdiClose,
    },

    formValid: false,
    loginError: false,

    errorMsg: '',

    username: '',
    password: '',
    
    usernameDisabled: false,
  }),

  methods: {
    async submitLogin() {
      if (this.processing) {
        return
      }

      if (!this.$validate.DataValid(this.username) || !this.$validate.DataValid(this.password)) {
        this.errorMsg = this.$t('message.required')
        this.loginError = true
        return
      }

      this.loginError = false
      this.$store.dispatch('setLoading', true)

      try {
        const data = await this.$Fetcher.StudentLogin(this.username, this.password)

        const schools = [];
        if (data.school_id !== undefined && data.school_id !== null && data.school_id > -1) {
          schools.push(data.school_id)
        }
        await this.setUserData({
          id: data.id,
          user_type: 'student',
          last_login: new Date().getTime(),
          username: data.username,
          selected_school: schools,
          schools: schools
        })

        this.$router.replace({ name: 'StudentUserDetails', params: { username: data.username } });

        if (this.$validate.DataValid(defaultPage[data.user_type])) {
          this.$router.replace(defaultPage[data.user_type])
        }
      } catch (err) {
        this.$common.error(err)
        this.errorMsg = err
        this.loginError = true
        this.$store.dispatch('setLoading', false)
      }
      return
    },
  },

  created() {
    if (this.$route.params.username) {
      this.username = this.$route.params.username;
      this.usernameDisabled = true;
    }
  }
}
</script>
